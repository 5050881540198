import React from 'react';
// import imagePath from "./cropped-well-group_favicon-192x192.png";
// import stylesz from './CareBizChat.css';
import './CareBizChat.css';


// import classNames from 'classnames';
import { withStyles, createStyles } from '@material-ui/styles';
// import Button from '@material-ui/core/Button';
// import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ImageLogo from './cropped-well-group_favicon-192x192.png';
import AccountManager from './utils/AccountManager';
import { TextField } from '@material-ui/core';

import Swal from 'sweetalert2'

// const styles = ({ spacing }) => createStyles({
// 	root: {
// 		position: 'absolute',
// 		top: 0,
// 		right: 0,
// 		bottom: 0,
// 		left: 0,
// 		backgroundImage: 'linear-gradient(90deg,rgb(250,196,1) 0%,rgb(250,170,1) 100%)',
// 		zIndex: 999,
// 	},
// 	inner: {
// 		display: 'flex',
// 		flexDirection: 'column',
// 		maxWidth: 500,
// 		margin: 'auto',
// 		color: 'white',
// 		marginTop: '10vh',
// 		padding: '5vh',
// 	},
// 	button: {
// 		color: 'white',
// 		marginTop: spacing(3),
// 		float: 'right',
// 	},
// 	buttonDisabled: {
// 		color: 'rgba(255,255,255,0.7)',
// 	},
// 	label: {
// 		color: 'rgba(255,255,255,0.8)',
// 	},
// 	field: {
// 		color: 'white',
// 		marginBottom: spacing(2),
// 		'&:before': {
// 			borderColor: 'rgba(255,255,255,0.5)',
// 		},
// 		'&:hover:before': {
// 			borderColor: 'rgba(255,255,255,0.5) !important',
// 		},
// 	},
// 	warning: {
// 		padding: '1rem 3rem',
// 		marginTop: 24,
// 		backgroundColor: '#214a75',
// 	},
// });
const styles = ({ spacing }) =>
	createStyles({
	  carebizChat: {
		fontFamily: 'Arial, sans-serif',
		color: '#f79473',
		textAlign: 'center',
		padding: '20px',
	  },
	  header: {
		backgroundColor: '#b3e2cc',
		padding: '10px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	  },
	  warning: {
		padding: '1rem 3rem',
		marginTop: 24,
		backgroundColor: '#214a75',
	  },
	  icon: {
		marginRight: '10px',
	  },
	  formContainer: {
		marginTop: '20px',
	  },
	  formGroup: {
		marginBottom: '20px',
	  },
	  formGroupLabel: {
		display: 'block',
		fontSize: '18px',
	  },
	  formGroupInput: {
		width: 'calc(100% - 20px)',
		padding: '10px',
		border: '1px solid #f79473',
		borderRadius: '5px',
		fontSize: '16px',
	  },
	  forgotPassword: {
		marginBottom: '20px',
	  },
	  forgotPasswordLink: {
		color: '#f79473',
		textDecoration: 'none',
	  },
	  loginBtn: {
		display: 'block',
		width: 'calc(100% - 40px)',
		margin: '10px auto',
		padding: '10px',
		border: 'none',
		borderRadius: '5px',
		backgroundColor: '#b3e2cc',
		color: '#f79473',
		fontSize: '18px',
		cursor: 'pointer',
	  },
	  createAccountBtn: {
		display: 'block',
		width: 'calc(100% - 40px)',
		margin: '10px auto',
		padding: '10px',
		border: 'none',
		borderRadius: '5px',
		backgroundColor: '#b3e2cc',
		color: '#f79473',
		fontSize: '18px',
		cursor: 'pointer',
	  },
	  footer: {
		marginTop: '20px',
	  },
	  footerLink: {
		color: '#f79473',
		textDecoration: 'none',
		display: 'block',
	  },
	  logo: {
		marginTop: '10px',
		fontSize: '14px',
	  },
	})

const URLPREFIX = 'https://';
const STATUS = {
	Idle: 0,
	Connecting: 1,
	Connected: 2,
};

class AddAccount extends React.Component<any, any> {
	state = {
		status: STATUS.Idle,
		warning: '',
		// url: "http://chat.dotdash8.com:5281/http-bind/",
		// url: "http://chat.dotdashtech.com:5280/http-bind/",
		url: "https://chat.carebiz.dotdashtech.com/http-bind/",
		// url: "http://xmpp.dev.dotdashtech.com:5280/http-bind",
		jid: '',
		password: '',
	};

	onChange = name => ev => {
		let value = ev.target.value;

		if (name === 'url') {
			value = this.hasHTTPSPrefix(value) ? value : URLPREFIX + value;
		}

		this.setState({ [name]: value });
		this.setState({ warning: '' });
	};

	doForgotPassword(e){
        // var win = window.open("https://www.hitrex.haws.co.jp/", '_blank');
        // win.focus();
		let subject = encodeURI("パスワードをお忘れですか");
		let body = encodeURI("アカウントのパスワードを忘れてしまったのでリセットしてください");
        let mailto = "mailto:info@example.com?subject="+subject+"&body="+body;
        window.location.href = mailto;
        e.preventDefault();
    }

	doContactUs(e){
		// window.location.href = "tel:+60123456789";

        let subject = encodeURI("");
		let body = encodeURI("");
        let mailto = "mailto:info@example.com?subject="+subject+"&body="+body;
        window.location.href = mailto;
        e.preventDefault();
    }

	onSubmit2 = ev => {
		ev.preventDefault();
		this.props.onConnected();
	};

	onSubmit = ev => {
		ev.preventDefault();

		this.setState({ status: STATUS.Connecting });

		let { url, jid, password } = this.state;
		let domain = this.getDomainFromJid(jid);
		if (!domain){
			domain = 'chat.carebiz.dotdashtech.com'
			jid = jid+'@'+domain
		}
		try{
			(window as any).JSXC.testBOSHServer(url, domain)
			.then(() => {
				return this.props.jsxc.start(url, jid, password);
			})
			.then(() => {
				AccountManager.get().add(url, jid, password);

				this.setState({ status: STATUS.Connected });

				this.props.onConnected();
			})
			.catch(error => {
				if (typeof error === 'string') {
					this.setState({ warning: error });
					
				} else if (error.message) {
					this.setState({ warning: error.message });
				} else {
					this.setState({
						warning:
							'Sorry we could not connect. Maybe your ID or password is wrong.',
					});
				}

				Swal.fire({
					title: 'Error!',
					text: this.state.warning,
					icon: 'error',
					confirmButtonColor: "#FFAA7B",
				})

				this.setState({ status: STATUS.Idle });
			});
		} catch(error){
			if (typeof error === 'string') {
				this.setState({ warning: error });
			} else if (error.message) {
				this.setState({ warning: error.message });
			} else {
				this.setState({
					warning:
						'Sorry we could not connect. Maybe your ID or password is wrong.',
				});
			}
			Swal.fire({
				title: 'Error!',
				text: this.state.warning,
				icon: 'error',
				confirmButtonColor: "#FFAA7B",
			})
			this.setState({ status: STATUS.Idle });
		}
	};

	getDomainFromJid(jid) {
		let parts = jid.split('@');

		return parts[1];
	}

	hasHTTPSPrefix(url = '') {
		for (let i = 0; i < url.length && i < URLPREFIX.length; i++) {
			if (url[i] !== URLPREFIX[i]) return false;
		}

		return true;
	}

	render() {
		const { classes } = this.props;
		const { status/*, url*/ } = this.state;
		const disabled = status === STATUS.Connecting;
		// let buttonLabel = disabled ? 'Connecting...' : 'Connect';

		return (
			// <div className={classes.root}>
			// 	<div className={classes.inner}>
			// 	<NavBar 
			// 		brandName="My Brand" 
			// 		imageSrcPath={imagePath} 
			// 		navItems={items}/>

			// 		<img
			// 			src={ImageLogo}
			// 			style={{ maxWidth: '50vh',alignSelf:'center', margin: '3vh', maxHeight: '50vh' }}
			// 			alt="Logo"
			// 		/>

			// 		<form onSubmit={this.onSubmit}>
			// 			{/* <TextField
			// 				autoFocus
			// 				disabled={disabled}
			// 				required
			// 				label="BOSH Url"
			// 				value={url}
			// 				onChange={this.onChange('url')}
			// 				InputProps={{
			// 					className: classes.field,
			// 				}}
			// 				InputLabelProps={{
			// 					className: classes.label,
			// 				}}
			// 				fullWidth
			// 			/> */}
			// 			<TextField
			// 				// type="email"
			// 				disabled={disabled}
			// 				required
			// 				label="SSO Account"
			// 				onChange={this.onChange('jid')}
			// 				InputProps={{
			// 					className: classes.field,
			// 				}}
			// 				inputProps={{
			// 					autoCapitalize: 'none',
			// 					autoCorrect: "off",
			// 				}}
			// 				InputLabelProps={{
			// 					className: classes.label,
			// 				}}
			// 				autoComplete="off"
			// 				fullWidth
			// 			/>
			// 			<TextField
			// 				type="password"
			// 				disabled={disabled}
			// 				required
			// 				label="Password"
			// 				onChange={this.onChange('password')}
			// 				InputProps={{
			// 					className: classes.field,
			// 				}}
			// 				inputProps={{
			// 					autoCapitalize: 'none',
			// 					autoCorrect: "off",
			// 				}}
			// 				InputLabelProps={{
			// 					className: classes.label,
			// 				}}
			// 				autoComplete="off"
			// 				fullWidth
			// 			/>

			// 			{/* @TODO clear button */}
			// 			<Button
			// 				type="submit"
			// 				disabled={disabled}
			// 				className={classNames(
			// 					classes.button,
			// 					disabled && classes.buttonDisabled
			// 				)}
			// 				color="primary"
			// 			>
			// 				{buttonLabel} <KeyboardArrowRight />
			// 			</Button>
			// 			<div style={{ clear: 'both' }} />

			// 			{this.state.warning ? (
			// 				<div className={classes.warning}>{this.state.warning}</div>
			// 			) : (
			// 				''
			// 			)}
			// 		</form>
			// 	</div>
			// </div>
			<div className="cbcarebiz-chat root">
      <header className="cbheader">
        <div className="icon">icon</div>
        <h1>CareBiz Chat</h1>
      </header>
      <div className="cbform-container">
        <form onSubmit={this.onSubmit}>
          <div className="cbform-group">
            <label htmlFor="userId">User ID or MailAddress</label>
            <TextField
				// type="email"
				disabled={disabled}
				required
				// label="SSO Account"
				onChange={this.onChange('jid')}
				InputProps={{
					className: classes.field,
					disableUnderline: true,
				}}
				inputProps={{
					autoCapitalize: 'none',
					autoCorrect: "off",
				}}
				InputLabelProps={{
					className: classes.label,
				}}
				autoComplete="off"
				fullWidth
			/>
          </div>
          <div className="cbform-group">
            <label htmlFor="password">Password</label>
            <TextField
				type="password"
				disabled={disabled}
				required
				onChange={this.onChange('password')}
				InputProps={{
					className: classes.field,
					disableUnderline: true,
				}}
				inputProps={{
					autoCapitalize: 'none',
					autoCorrect: "off",
				}}
				InputLabelProps={{
					className: classes.label,
				}}
				autoComplete="off"
				fullWidth
			/>
          </div>
          <div className="cbforgot-password">
            <div style={{cursor:"pointer"}} onClick={this.doForgotPassword}>Forgot ID/Password?</div>
          </div>
          <button type="submit" className="cblogin-btn">Log in</button>
	  <hr style={{color:"#d9d9d9", borderWidth:"0.1px", margin:"25px 0px"}}/>
          {/* <button type="button" className="cbcreate-account-btn">Create a new account</button> */}
		  {/* {this.state.warning ? (
							<div className={classes.warning}>{this.state.warning}</div>
						) : (
							''
						)} */}
        </form>
      </div>
      <div className="cbfooter" style={{display:"flex", marginLeft:"10px", alignItems:"space-between"}}>
        <div style={{cursor:"pointer"}} onClick={this.doContactUs}>Contact Us</div>
      </div>
	  <div style={{bottom:0, backgroundColor:"white", position:"absolute",width:"100vw"}}>
	  	<hr style={{color:"#d9d9d9", borderWidth:"0.1px"}}/>
		<div className="logo" style={{display:"flex", justifyContent:"flex-end", marginRight:"10px"}}>
				<div style={{display:"flex",flexDirection:"column"}}>
				<img
					src={ImageLogo}
					style={{ maxWidth: '5vh',alignSelf:'center', maxHeight: '5vh' }}
					alt="Logo" />
				<span style={{color:"#AFE1B9"}}>2024 © Wellgroup</span>
				</div>
		</div>
	  </div>
    </div>

		);
	}
}

export default withStyles(styles, { withTheme: true })(AddAccount);
// export default AddAccount;
