import React from 'react';
import AddAccount from './AddAccount';
import Overview from './Overview';
import { ThemeProvider } from '@material-ui/styles';
import AccountManager from './utils/AccountManager';
import ConnectionWatcher from './utils/ConnectionWatcher';
import { createTheme } from '@material-ui/core/styles';

import Swal from 'sweetalert2'
import Dashboard from './Dashboard';
import SimpleBottomNavigation from './BottomNavbar';
import Blank from './Blank';
// import SwipeableTemporaryDrawer from './SwipableDrawer';

const theme = createTheme({
	palette: {
		primary: {
			light: 'rgba(#ffffff, 0.6)',
			main: 'rgba(#ffffff, 0.8)',
			dark: '#ffffff',
			contrastText: '#fff',
		},
		secondary: {
			light: 'rgba(#ffffff, 0.6)',
			main: 'rgba(#ffffff, 0.8)',
			dark: '#ffffff',
			contrastText: '#fff',
		},
		background: '#2e7bcf',
	},
} as any);

const SUSPEND = 0;
const LOADING = 1;
const ADD_ACCOUNT = 2;
const OVERVIEW = 3;
const ADVERTISEMENT = 4;
const JSXCHANDLER = 5;
const BLANK = 6;

class App extends React.Component {
	state = {
		page: LOADING,
		currentNavigation: "Home",
  };

  private jsxc;

	onHandleNavigation = (value) => {
		this.setState({currentNavigation: value});
	};
	
	componentWillUnmount(): void {
		window.onpopstate = null
	}

	componentDidMount() {
		window.history.pushState(null, 'PageName', window.location.href)
		window.onpopstate = (e: PopStateEvent) => {
			if (window.confirm('Are you sure you want to leave?')) {
				window.onpopstate = (e) => {
					window.history.back()
				}
				
				window.history.back()
			} else {
				window.history.pushState(null, 'PageName', window.location.href)
			}
		}
	

		window.addEventListener("build",(e)=>{
			// console.log('lol', e);
		},false);

		window.addEventListener("gotochat",(e)=>{
			this.onNavigationChanged('Chat');
		},false);

		this.jsxc = new (window as any).JSXC({
			connectionCallback: (jid, status, condition) => {
				if (jid) {
					connectionWatcher.callback(jid, status, condition);
				}

				if (status === 7) {
					if (condition === 'forced') {
						console.log('jid',jid,'status',status,'condition',condition);
						accountManager.disableAutoConnect();
					} else if (!accountManager.enableAutoConnect()) {
						this.connect(accountManager);
					}
				}
			},
			onUserRequestsToGoOnline: () => {
				accountManager.enableAutoConnect();

				this.connect(accountManager);
			},
		});

		this.jsxc.disableDebugMode();
		// bootstrap ui
		this.jsxc.start();

		let accountManager = AccountManager.get(this.jsxc);
		let connectionWatcher = new ConnectionWatcher(
			jid => accountManager.connect(jid),
			jid => accountManager.disable(jid)
		);

		if (this.jsxc.numberOfCachedAccounts === 0) {
			this.connect(accountManager);
		} else {
			this.setState({ page: ADVERTISEMENT });
			this.onHandleNavigation("Home");
		}

		// this.jsxc.addMenuEntry({
		// 	id: 'account-manager',
		// 	label: 'Accounts',
		// 	handler: ev => {
		// 		this.setState({ page: OVERVIEW });
		// 	},
		// 	offlineAvailable: true,
		// });

		this.jsxc.addMenuEntry({
			id: 'logout',
			label: 'Logout',
			handler: ev => {
				this.setState({ page: ADD_ACCOUNT });
				let array = JSON.parse(localStorage.getItem('intervalArray'));
				if(array){
					array.forEach( (element) => {
						clearInterval(element);
					});
				}
				this.jsxc.disconnect()
				this.jsxc.deleteAllData();
				Swal.fire({
					title: 'Loading...',
					html: 'Please wait...',
					allowEscapeKey: false,
					allowOutsideClick: false,
					didOpen: () => {
					  Swal.showLoading()
					}
				  });
				  
				setTimeout(() => {
					localStorage.clear();	
					Swal.close();
					window.location.reload();
				}, 2000);
				// localStorage.clear();
			},
			offlineAvailable: true,
		});
		// let logoutButton = document.getElementsByClassName('jsxc-logout')[0];
		// // console.log(logoutButton);
		// setTimeout(() => {
		// 	console.log(logoutButton);
		// 	console.log(this.jsxc);
		// 	// this.jsxc.watchLogoutClick(logoutButton);
		// }, 1000);
		// // console.log(this.jsxc);
	}

	connected = () => {
		this.setState({ page: SUSPEND });
	};

	advertisemented = () => {
		this.setState({ page: ADVERTISEMENT });
		this.onHandleNavigation("Home");
	};

	connect = accountManager => {
		let connectionPromises = accountManager.connectAllEnabled();
		let numberOfStoredAccounts = accountManager.getNumberOfStoredAccounts();

		if (numberOfStoredAccounts === 0) {
			this.setState({ page: ADD_ACCOUNT });
		} else {
			if (connectionPromises.length) {
				// console.log("LOL 2")
				connectionPromises.forEach(([jid, promise]) => {
					promise
						.then(() => {
							console.log(jid, 'connected');
						})
						.catch(err => {
							console.log(jid, err); //@TODO show error; disable account?
						});
				});
			} else {
				// console.log("LOL 1")
				this.jsxc.start();
			}
			// console.log('LOL');
			this.setState({ page: ADVERTISEMENT });
			this.onHandleNavigation("Home");
		}
	};

	onOverviewClose = () => {
		this.setState({ page: SUSPEND });
	};

	onAddAccount = () => {
		this.setState({ page: ADD_ACCOUNT });
	};
	
	onNavigationChanged = (newValue: string) => {
		// console.log("newValue 1", newValue);
		this.setState({currentNavigation:newValue})
		switch (newValue) {
			case "Chat":
				this.setState({ page: SUSPEND });
				break;
			case "Video":
				const event = new CustomEvent("build", { detail: "lim peh gao siao" });
				window.dispatchEvent(event);

				this.setState({ page: BLANK });
				break;
			case "Calendar":
				this.setState({ page: BLANK });
				break;
			case "Mention":
				this.setState({ page: BLANK });
				break;
			case "Home":
				this.setState({ page: ADVERTISEMENT });
				break;
			case "Dots":
				this.setState({ page: BLANK });
				break;
			default:
				break;
		}
	};

	render() {
		let { currentNavigation } = this.state;
		let page: any = '';
		let showmenu: any = false;
		switch (this.state.page) {
			case LOADING:
				page = <p>Loading</p>;
				break;
			case ADD_ACCOUNT:
				page = <AddAccount jsxc={this.jsxc} onConnected={this.advertisemented} closable={true} />;
				// showmenu = true;
				break;
			case ADVERTISEMENT:
				page = <Dashboard />;
				showmenu = true;
				break;
			case OVERVIEW:
				page = (
					<Overview
						onClose={this.onOverviewClose}
						onAddAccount={this.onAddAccount}
					/>
				);
				break;
			case JSXCHANDLER:
				page = ''
				showmenu = true;
				break;
			case BLANK:
				page = <Blank name={currentNavigation}/>
				showmenu = true;
				break;
			case SUSPEND:
				page = ''
				showmenu = true;
				break;
			default:
				page = '';
		}
		return <ThemeProvider theme={theme}>
			{/* <SwipeableTemporaryDrawer /> */}
			{page}
			{showmenu && <SimpleBottomNavigation value={currentNavigation} onNavigationChanged={this.onNavigationChanged}/>}
		</ThemeProvider>;
	}
}

export default App;
