import React from 'react';

const VideoIcon = () => (
  <svg width="43" height="21" viewBox="0 0 43 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.4325 0H2.08582C0.933615 0 0 0.932299 0 2.0845V18.7439C0 19.8961 0.933615 20.8297 2.08582 20.8297H23.4325C24.5847 20.8297 25.5183 19.8961 25.5183 18.7439V2.0845C25.5183 0.932299 24.5847 0 23.4325 0Z" fill="#B3B3B3"/>
    <path d="M41.3406 3.71128C40.8366 3.31649 40.1807 3.17502 39.5584 3.32546L29.3813 5.80476C28.4452 6.0324 27.7881 6.86956 27.7881 7.83272V12.7707C27.7881 13.7327 28.4452 14.5697 29.3813 14.7974L39.5583 17.278C40.1807 17.4272 40.8365 17.2857 41.3406 16.8909C41.8433 16.4948 42.1379 15.8905 42.1379 15.2514V5.35211C42.1379 4.71173 41.8434 4.10731 41.3406 3.71128Z" fill="#B3B3B3"/>
  </svg>

);

export default VideoIcon;
