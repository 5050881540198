import React from 'react';

class Blank extends React.Component<any, any> {
    
  render(){

    return (
        <div className="dbdashboard">
            <div style={{height:"100%", alignContent:"center"}} >{this.props.name} Coming Soon</div>
        </div>
    );
    }
}

export default Blank;
