import React from 'react';
import { /*Theme, createStyles, */ makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
// import ChatIcon from './chat.svg';

import ChatIcon from './chaticon';
import VideoIcon from './videoicon';
import CalendarIcon from './calendaricon';
import MentionIcon from './mentionicon';
import HomeIcon from './homeicon';
// import DotsIcon from './dotsicon';
// const useStyles = makeStyles((theme: Theme) =>
//     createStyles({
//       root: {
//         '& > svg': {
//           margin: theme.spacing(2),
//         },
//       },
//     }),
//   );
  
//   function ChatIcon(props: SvgIconProps) {
//     return (
//       <SvgIcon {...props}>
//         <path d="M20.4986 5.38456e-06C14.9211 5.38456e-06 9.85005 1.73801 6.12356 4.61775C2.39707 7.49749 0.000205 11.5804 0 16.1288C0.001435 23.1127 5.68916 29.0368 13.7303 31.2631L15.6634 37.1758C15.7277 37.3728 15.8413 37.5496 15.9929 37.6888C16.1445 37.8281 16.3291 37.925 16.5287 37.9701C16.7282 38.0153 16.9359 38.0071 17.1314 37.9465C17.3269 37.8858 17.5035 37.7747 17.6441 37.624L22.8233 32.0652C32.9747 31.146 40.992 24.5036 41 16.1276C41 11.5789 38.6029 7.49603 34.8764 4.61629C31.15 1.73655 26.076 -0.00144958 20.4984 -0.00144958L20.4986 5.38456e-06ZM20.4986 2.39986C25.5899 2.39986 30.178 4.00421 33.4433 6.52731C36.7085 9.05041 38.6339 12.4331 38.6341 16.129C38.6251 23.0548 31.6303 29.1389 22.1259 29.7895C21.829 29.8104 21.5509 29.9442 21.3469 30.1642L17.2823 34.5178L15.7991 29.9806C15.7358 29.7872 15.625 29.6133 15.4772 29.4755C15.3294 29.3376 15.1495 29.2401 14.9543 29.1924C7.31481 27.3261 2.36447 21.957 2.36324 16.129C2.36324 12.4331 4.29188 9.05041 7.55712 6.52731C10.8224 4.00421 15.4074 2.39986 20.4986 2.39986ZM13.4056 14.3356C12.2301 14.3356 11.2771 15.2492 11.2768 16.3759C11.2766 17.5027 12.2303 18.4181 13.406 18.4175C14.5817 18.4169 15.5337 17.5031 15.5335 16.3759C15.5333 15.2488 14.581 14.3356 13.406 14.3356H13.4056ZM20.4986 14.3356C19.3231 14.3356 18.3701 15.2492 18.3698 16.3759C18.3696 17.5027 19.3233 18.4181 20.499 18.4175C21.6747 18.4169 22.6267 17.5031 22.6265 16.3759C22.6263 15.2488 21.674 14.3356 20.499 14.3356H20.4986ZM27.5916 14.3356C26.4161 14.3356 25.463 15.2492 25.4628 16.3759C25.4626 17.5027 26.4163 18.4181 27.592 18.4175C28.7677 18.4169 29.7197 17.5031 29.7195 16.3759C29.7193 15.2488 28.767 14.3356 27.592 14.3356H27.5916Z" fill="#9B9CA6"/>
//       </SvgIcon>
//     );
//   }
  


const useStyles1 = makeStyles({
  root: {
    width: 'calc(100% - 2px)',
    position: "fixed",
    left: 0,
    bottom: 0,
    // backgroundColor: "white",
    backgroundImage: "linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%), linear-gradient(to bottom, rgba(61, 157, 227, 1) 0%, rgba(61, 157, 227, 1) 100%)",
    backgroundClip: "content-box, padding-box;",
    padding:"1px",
    minWidth:"0px",
    zIndex:999,
  },
});

export default function SimpleBottomNavigation(props) {
  const classes = useStyles1();
//   const classes2 = useStyles();
  const [value, setValue] = React.useState(props.value || "Home");
  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    if(newValue !== value)
    {
        setValue(newValue)
        props.onNavigationChanged(newValue);
    };
    if(value === "Video"){
      props.onNavigationChanged(newValue);
    }
  };
  
  React.useEffect(() => {
    if (props.value !== value) {
      setValue(props.value);
    }
  }, [props.value, value]);

  return (
    <BottomNavigation
    //   value={value}
    //   onChange={(event, newValue) => {
    //     setValue(newValue);
    //   }}
    //   showLabels
      className={classes.root}
      value={value} onChange={handleChange}
    >
      <BottomNavigationAction value="Chat" icon={<ChatIcon />} />
      <BottomNavigationAction value="Video" icon={<VideoIcon />} />
      <BottomNavigationAction value="Calendar" icon={<CalendarIcon />} />
      <BottomNavigationAction value="Mention" icon={<MentionIcon />} />
      <BottomNavigationAction value="Home" icon={<HomeIcon />} />
      {/* <BottomNavigationAction value="Dots" icon={<DotsIcon />} / */}
    </BottomNavigation>
  );
}
