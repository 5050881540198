import React from 'react';
import './Dashboard.css';
// import GroupAddIcon from './useraddgroupicon';
// import UserAddIcon from './useraddicon';
// import MagnifyIcon from './magnifyicon';
// import ImageLogo from './img/smiletogether.png';

class Dashboard extends React.Component<any, any> {

    mailto: string = "mailto:info@example.com?subject=fk&body=me";
    doThis(){
        var win = window.open("https://www.hitrex.haws.co.jp/", '_blank');
        win.focus();

    }

    doThis2(e){
        // var win = window.open("https://www.hitrex.haws.co.jp/", '_blank');
        // win.focus();
        
        window.location.href = this.mailto;
        e.preventDefault();
    }

  render(){

    return (
        <div className="dbdashboard">
        {/* <header className="dbheader">
            <div className="dbmenu-icon">☰</div>
            <div style={{display:"flex",justifyContent:"space-between",flexDirection:"row", width:"100%"}}>
                <div className="dbuser-info" style={{display:"flex",flexDirection:"row",alignSelf:"flex-start"}}>
                    <div className="dbuser-icon">
                        
                    </div>
                    <div>
                        <div className="dbusername">username</div>
                        <div className="dbuser-id">user_ID</div>
                    </div>
                </div>

                <div className="dbheader-icons" style={{alignItems:"center"}}>
                    <div className="dbheader-icon"><UserAddIcon /></div>
                    <div className="dbheader-icon"><GroupAddIcon /></div>
                    <div className="dbheader-icon"><MagnifyIcon /></div>
                </div>
            </div>
        </header> */}
        <div className="dbadvertisement" onClick={this.doThis}>
            {/* advertisement */}
        </div>
        <div className="dbnotice">
            notice
        </div>
        {/* <form action="mailto:info@example.com" 
            method="get">
            <button 
                    type="submit">
                    Send me email 
            </button>
        </form> */}
        <div className="dbpost">
            {/* <a href="tel:+60123456789"> */}
                <div className="dbpost-image-1"></div>
            {/* </a> */}
            <div className="dbpost-info">
            <div className="dbpost-icon">icon1</div>
            <div className="dbpost-title">Title</div>
            <div className="dbpost-date">date 0000/00/00</div>
            </div>
        </div>
        
	    <hr style={{color:"#d9d9d9", borderWidth:"0.1px"}}/>
        
        <div className="dbpost">
            <div className="dbpost-image">image</div>
            <div className="dbpost-info">
            <div className="dbpost-icon">icon</div>
            <div className="dbpost-title">Title</div>
            <div className="dbpost-date">date 0000/00/00</div>
            </div>
        </div>
	    <hr style={{color:"#d9d9d9", borderWidth:"0.1px"}}/>
        {/* <footer className="dbfooter">
            <div className="dbfooter-icon">💬</div>
            <div className="dbfooter-icon">🎥</div>
            <div className="dbfooter-icon">📅</div>
            <div className="dbfooter-icon">📧</div>
            <div className="dbfooter-icon">🏠</div>
            <div className="dbfooter-icon">⋯</div>
        </footer> */}
        {/* <SimpleBottomNavigation/> */}

        </div>
    );
    }
}

export default Dashboard;
