import React from 'react';

const HomeIcon = () => (
  <svg width="45" height="41" viewBox="0 0 45 41" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M38.0742 15.5725L31.9841 9.48727L27.1505 4.65978L22.5079 0L0 22.4909L3.33352 25.8223L6.32408 22.8345V40.4882H19.5302H25.4696H38.6757V22.8319L41.6663 25.8226L45 22.4909L38.0742 15.5725ZM35.8715 37.684H26.3315V27.2109H18.6683V37.684H9.1285V20.0322L22.5077 6.66387L35.8715 20.0275V37.684Z" fill="#B3B3B3"/>
  </svg>

);

export default HomeIcon;
